import React from 'react';
import { Navbar, Avatar } from 'flowbite-react';
import {
  IoHomeOutline,
  IoAddOutline,
  IoGridOutline,
  IoSettingsOutline,
} from 'react-icons/io5';
import profile3 from '../images/profile-3.svg';
import logo from '../images/logo.svg';
import { Flowbite } from 'flowbite-react';
import type { CustomFlowbiteTheme } from 'flowbite-react';

const Layout = ({ children }: { children: any }) => {
  const customTheme: CustomFlowbiteTheme = {
    button: {
      color: {
        cyan: 'bg-[#003f88] hover:bg-[#003f88] text-white',
      },
      outline: {
        on: 'flex justify-center bg-white text-[#003f88] transition-all duration-75 ease-in group-enabled:group-hover:bg-opacity-0 group-enabled:group-hover:text-inherit dark:bg-[#003f88] dark:text-white w-full',
      }
    },
    navbar: {
      link: {
        base: 'block py-2 pr-4 pl-3',
        active: {
          on: 'text-[#003f88]',
        }
      },
    },
    textInput: {
      field: {
        input: {
          colors: {
            cyan: 'bg-gray-50 border-gray-300 text-gray-900 placeholder-[#003f88] focus:border-[#003f88] focus:ring-[#003f88] dark:border-[#003f88] dark:bg-[#003f88] dark:focus:border-[#003f88] dark:focus:ring-[#003f88]',
          }
        }
      },
    },
    select: {
      field: {
        select: {
          colors: {
            cyan: 'bg-gray-50 border-gray-300 text-gray-900 focus:border-[#003f88] focus:ring-[#003f88] dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-[#003f88] dark:focus:ring-[#003f88]',
          }
        }
      },
    },
    toggleSwitch: {
      toggle: {
        base: 'toggle-bg h-6 w-11 rounded-full border',
        checked: {
          color: {
            cyan: 'bg-[#003f88] border-[#003f88]',
          }
        }
      }
    },
  };


  return (
    <Flowbite theme={{ theme: customTheme }}>
    <div className='min-h-[100vh] min-w-[100vw] w-full bg-slate-50 pt-12'>
      <Navbar rounded className="fixed top-0 left-0 right-0 z-10">
        <Navbar.Brand href='/'>
          <img alt='' src={logo} className='mr-2 h-6 w-6' />
          <span className='self-center whitespace-nowrap text-xl font-semibold dark:text-white'>
            Sample app
          </span>
        </Navbar.Brand>
        <div className="flex flex-row">
          <Navbar.Link active={window.location.pathname === '/'} href='/'>
            <IoHomeOutline className='mr-2 h-6 w-6' />
          </Navbar.Link>
          {/* ITS NOT A LINK!  */}

          <Navbar.Link href='/#addnew'>
            <IoAddOutline className='mr-2 h-6 w-6' id='menu-add' />
          </Navbar.Link>
          <Navbar.Link
            active={window.location.pathname === '/projects'}
            href='/projects'
          >
            <IoGridOutline className='mr-2 h-6 w-6' id='menu-projects' />
          </Navbar.Link>

          <Navbar.Link
            active={window.location.pathname === '/settings'}
            href='/settings'
          >
            <IoSettingsOutline className='mr-2 h-6 w-6' id='menu-settings' />
          </Navbar.Link>
          <Navbar.Brand href='#'>
            <Avatar img={profile3} rounded className='mr-2 h-6 w-6' />
          </Navbar.Brand>
          </div>
      </Navbar>
      <div className='w-auto h-auto pt-20'>{children}</div>
    </div>
    </Flowbite>
  );
};

export default Layout;
