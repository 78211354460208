import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Card, Badge, Avatar } from 'flowbite-react';
import { format } from 'date-fns';
import profile1 from '../images/profile-1.svg';
import profile2 from '../images/profile-2.svg';
import profile3 from '../images/profile-3.svg';
interface ItemProps {
  item: any;
  index: number;
  onTaskClicked: any;
}

const Item: React.FC<ItemProps> = ({ item, index, onTaskClicked }) => {
  const getProfile = (profile: string) => {
    switch (profile) {
      case '1':
        return profile1;
      case '2':
        return profile2;
      case '3':
        return profile3;
      default:
        return profile1;
    }
  };
  return (
    <Draggable draggableId={item.id.toString()} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Card
            style={
              snapshot.isDragging
                ? {
                    transition: 'all 0.3s ease',
                    transform: 'rotate(-2deg)',
                  }
                : {
                    transition: 'all 0.3s ease',
                    transform: 'rotate(0deg)',
                  }
            }
            className=' mb-4'
            id={`card-${item.id}`}
            onClick={() => onTaskClicked()}
          >
            <p className='text-lg'>{item.title}</p>
            <p className='text-sm -mt-4'>{item.description}</p>
            <div className='flex flex-row items-center justify-between'>
              <Badge
                size='sm'
                color={item.labelcolor}
                className='rounded-full py-1 px-4 text-xs'
              >
                {item.label}
              </Badge>
              <Avatar rounded size='sm' img={getProfile(item.profile)} />
            </div>
            <p className='text-xs text-gray-600 -mt-2'>
              {format(new Date(item.createdDate), 'MM/dd/yyyy')}
            </p>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

export default Item;
