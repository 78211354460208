import Column from './Column';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';

type TodoType = {
  columns: any;
  onChange: (data: any) => void;
  createNew: (data: any) => void;
  onTaskClicked: (data: any, type: any) => void;
};

function TodoSection({ columns, onChange, createNew, onTaskClicked }: TodoType) {
  const onDragEnd = ({ source, destination }: DropResult) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;

    // Make sure we're actually moving the item
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null;

    // Set start and end variables
    //@ts-ignore
    const start = columns[source.droppableId];
    //@ts-ignore
    const end = columns[destination.droppableId];

    // If start is the same as end, we're in the same column
    if (start === end) {
      // Move the item within the list
      // Start by making a new list without the dragged item
      const newList = start.list.filter(
        (_: any, idx: number) => idx !== source.index
      );

      // Then insert the item at the right location
      newList.splice(destination.index, 0, start.list[source.index]);

      // Then create a new copy of the column object
      const newCol = {
        id: start.id,
        name: start.name,
        list: newList,
      };

      // Update the state
      const newColumns = {
        ...columns,
        [newCol.id]: newCol,
      };

      onChange(newColumns);
      return null;
    } else {
      // If start is different from end, we need to update multiple columns
      // Filter the start list like before
      const newStartList = start.list.filter(
        (_: any, idx: number) => idx !== source.index
      );

      // Create a new start column
      const newStartCol = {
        id: start.id,
        name: start.name,
        list: newStartList,
      };

      // Make a new end list array
      const newEndList = end.list;

      // Insert the item into the end list
      newEndList.splice(destination.index, 0, start.list[source.index]);

      // Create a new end column
      const newEndCol = {
        id: end.id,
        name: end.name,
        list: newEndList,
      };

      // Update the state
      const newColumns = {
        ...columns,
        [newStartCol.id]: newStartCol,
        [newEndCol.id]: newEndCol,
      };

      onChange(newColumns);
      return null;
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className='grid grid-cols-3 gap-3'>
        {Object.values(columns).map((col: any) => (
          <Column col={col} key={col.id} createNew={() => createNew(col.id)} onTaskClicked={(data: any) => onTaskClicked(data, col.id)}/>
        ))}
      </div>
    </DragDropContext>
  );
}

export default TodoSection;
