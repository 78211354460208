import React from 'react';
import Item from './Item';
import { Droppable } from 'react-beautiful-dnd';
import { Button } from 'flowbite-react';
import { IoAdd } from 'react-icons/io5';

type ColumnProps = {
  col: {
    id: string;
    name: string;
    list: any[];
  };
  createNew: any;
  onTaskClicked: any;
};

const Column: React.FC<ColumnProps> = ({
  col: { list, id, name },
  createNew,
  onTaskClicked
}) => {
  return (
    <Droppable droppableId={id}>
      {(provided) => (
        <div className='flex flex-col mt-4 rounded min-w-[300px]'>
          <h2 className='font-xl font-bold bg-gray-200 text-gray-700 p-4 px-6 rounded rounded-b-none'>
            {name}
          </h2>
          <div
            className='bg-gray-100 p-4 flex flex-col rounded-b'
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {list.map((item, index) => (
              <Item key={item.id} item={item} index={index} onTaskClicked={() => onTaskClicked(item)}/>
            ))}
            {provided.placeholder}
          <Button color='cyan' outline onClick={() => createNew()}>
            <IoAdd className='mr-2 h-5 w-5' />
            <p>Add a task</p>
          </Button>
          </div>
          
        </div>
      )}
    </Droppable>
  );
};

export default Column;
