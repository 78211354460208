import React from 'react';
import Layout from '../components/Layout';
import { Badge, Card } from 'flowbite-react';
import { projects } from '../data';
import { format } from 'date-fns';

function Projects() {
  return (
    <Layout>
      <div className='grid grid-cols-2 gap-4'>
        {projects.map((item) => {
          return (
            <Card key={item.id} className='mb-4 flex flex-row'>
              <p className='text-lg'>{item.title}</p>
              <p className='text-sm -my-4'>{item.description}</p>
              <p className='text-sm'>
                {format(item.createdDate, 'MM/dd/yyyy')}
              </p>
              <Badge size='sm' className="w-40" color={item.color}></Badge>
            </Card>
          );
        })}
      </div>
    </Layout>
  );
}

export default Projects;
