import { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import TodoSection from '../components/TodoSection';
import { Button, Label, Modal, TextInput, Select } from 'flowbite-react';
import { initialColumns, projects } from '../data';
import { v4 as uuidv4 } from 'uuid';

function Home() {
  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [label, setLabel] = useState('');
  const [columns, setColumns] = useState(initialColumns);
  const [openModal, setOpenModal] = useState<string | undefined>();
  const [type, setType] = useState<string>('');

  useEffect(() => {
    const data = localStorage.getItem("@tasks");
    if (data === null) return;
    setColumns(JSON.parse(data));
  }, []);

  useEffect(() => {
    const handleHashChange = () => {
    if (window.location.hash === '#addnew') {
      setType('todo');
      setLabel(projects[0].title);
      setId('');
      setTitle('');
      setDescription('');
      setOpenModal('form-elements');
    } else {
      setOpenModal(undefined);
    }
  }
   // Call the handler once on initial render
   handleHashChange();

   // Listen for hash changes
   window.addEventListener('hashchange', handleHashChange);

   // Clean up the event listener when the component unmounts
   return () => {
     window.removeEventListener('hashchange', handleHashChange);
   };
  }, []);

  return (
    <Layout>
      <div className='min-w-[950px] w-11/12 lg:w-9/12 mx-auto overflow-x-auto bg-slate-50'>
        <TodoSection
          columns={columns}
          onChange={(data) => {
            setColumns(data)
            localStorage.setItem("@tasks", JSON.stringify(data));
          }}
          createNew={(type) => {
            setOpenModal('form-elements');
            setType(type);
            setLabel(projects[0].title);
            setId('');
            setTitle('');
            setDescription('');

          }}
          onTaskClicked={(item, type) => {
            setId(item.id);
            setTitle(item.title);
            setDescription(item.description);
            setLabel(item.label);
            setType(type);
            setOpenModal('form-elements');
          }}
        />
      </div>
      <Modal
        show={openModal === 'form-elements'}
        size='md'
        popup
        onClose={() => {
          setOpenModal(undefined)
          window.history.replaceState(null, '', window.location.pathname + window.location.search);
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className='space-y-6'>
            <h3 className='text-xl font-medium text-gray-900 dark:text-white'>
              {id === '' ? 'Create a new task': 'Edit task' }
            </h3>
            <div>
              <div className='mb-2 block'>
                <Label htmlFor='title' value='Title' />
              </div>
              <TextInput
                color="cyan"
                type='text'
                id='title'
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </div>
            <div>
              <div className='mb-2 block'>
                <Label htmlFor='description' value='Description' />
              </div>
              <TextInput
                color="cyan"
                type='text'
                id='description'
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </div>
            <div>
              <div className='mb-2 block'>
                <Label htmlFor='projects' value='Project' />
              </div>
              <Select
                color="cyan"
                value={label}
                onChange={(e) => setLabel(e.target.value)}
                id='projects'
              >
                {projects.map((item) => {
                  return <option key={item.id}>{item.title}</option>;
                })}
              </Select>
            </div>

            <div className='w-fit ml-auto'>
              <Button
                color="cyan"
                outline
                onClick={() => {
                  if (id === '') {
                    const newColumns = { ...columns };
                    //@ts-ignore
                    newColumns[type].list.push({
                      id: uuidv4(),
                      title,
                      description,
                      label,
                      labelcolor: projects.find((item) => item.title === label)
                        ?.color,
                      createdDate: new Date().toISOString(),
                      profile: '3'
                    });
                    setColumns(newColumns);
                    setOpenModal(undefined);
                    window.history.replaceState(null, '', window.location.pathname + window.location.search);
                    localStorage.setItem("@tasks", JSON.stringify(newColumns));
                  } else {
                    const newColumns = { ...columns };
                    //@ts-ignore
                    newColumns[type].list = newColumns[type].list.map(
                      (item: any) => {
                        if (item.id === id) {
                          return {
                            id,
                            title,
                            description,
                            label,
                            labelcolor: projects.find(
                              (item) => item.title === label
                            )?.color,
                            createdDate: new Date(),
                            profile: '3'
                          };
                        }
                        return item;
                      }
                    );
                    //@ts-ignore
                    setColumns(newColumns);
                    localStorage.setItem("@tasks", JSON.stringify(newColumns));
                    setOpenModal(undefined);
                    window.history.replaceState(null, '', window.location.pathname + window.location.search);
                  }
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
}

export default Home;
