import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import {
  Card,
  ToggleSwitch,
  TextInput,
  Label,
  Select,
  Button,
  Alert,
} from 'flowbite-react';
import { timezones } from '../data';
import { IoCheckmarkDoneOutline } from 'react-icons/io5';

function Settings() {
  const [userName, setUserName] = useState('John Doe');
  const [timezone, setTimezone] = useState('Europe/London - GMT');
  const [notifications, setNotifications] = useState(false);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    const dataFromStorage = localStorage.getItem("@settings");
    if (dataFromStorage === null) return;
    const data = JSON.parse(dataFromStorage)
    setUserName(data.username);
    setTimezone(data.timezone);
    setNotifications(data.notifications);
  }, []);

  return (
    <Layout>
      <div className='w-1/2 mx-auto'>
        <Card>
          <div>
            <div className='mb-2 block'>
              <Label htmlFor='username' value='Username' />
            </div>
            <TextInput
              color="cyan"
              id='username'
              type='text'
              value={userName}
              onChange={(e) => {
                setUserName(e.target.value);
              }}
            />
          </div>
          <div>
            <div className='mb-2 block'>
              <Label htmlFor='timezone' value='Time zone' />
            </div>
            <Select
              color="cyan"
              id='timezone'
              value={timezone}
              onChange={(e) => {
                setTimezone(e.target.value);
              }}
            >
              {timezones
                .sort((a, b) => a.timeZoneValue.localeCompare(b.timeZoneValue))
                .map((item, index) => {
                  return <option key={index}>{item.timeZoneValue}{' - '}{item.gmt}</option>;
                })}
            </Select>
          </div>
          <ToggleSwitch
            className="my-2"
            color="cyan"
            label='Enable notifications'
            checked={notifications}
            onChange={() => setNotifications(!notifications)}
          />
          <div className='w-fit ml-auto'>
          <Button
            color='cyan'
            outline
            onClick={() => {
              // localstorage save data
              const data = {
                username: userName,
                timezone: timezone,
                notifications: notifications,
              }
              localStorage.setItem("@settings", JSON.stringify(data));
              setShowToast(true);
              setTimeout(() => {
                setShowToast(false);
              }, 2000);
            }}
          >
            Save
          </Button>
          </div>
        </Card>
      </div>
      {showToast && (
        <div className='top-0 right-0 fixed z-10 m-10'>
          <Alert color='success'>
            <div className='flex flex-row'>
              <IoCheckmarkDoneOutline className='h-5 w-5' />
              <div className='ml-3 text-sm font-normal'>Successfully saved</div>
            </div>
          </Alert>
        </div>
      )}
    </Layout>
  );
}

export default Settings;
